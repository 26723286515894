import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import Page from '../components/common/Page.layout'
import SEO from '../components/common/SEO'
import Card from '../components/Blog/Blog.card'
import SocLinks from '../components/common/Socs'
import { RT, CTALink, H1, H4 } from '../components/UI'
import { useStoreActions } from '../store'

const Wrap = styled.div`
	.page-wrapper {
		min-height: calc(100vh - 178px);
		margin: 60px auto;
		padding: 0 ${props => props.theme.sizes.gutter.mobile.num}px;
		${props => props.theme.media.sdesk} {
			width: 65vw;
			max-width: 1000px;
			margin: 150px auto;
		}
		& > section {
			margin-bottom: 40px;
			${props => props.theme.media.sdesk} {
				margin-bottom: 100px;
			}
		}
		.rt-section {
			margin-bottom: 0;
			& > h1 {
				font-size: 1.5rem;
				line-height: 1.7rem;
				${props => props.theme.media.sdesk} {
					font-size: 2.5rem;
					line-height: 2.7rem;
				}
			}
			.rt {
				text-align: ${props => props.textAlign};
				ol,
				ul {
					text-align: left;
				}
				p {
				}
			}
		}
		.cta-section {
			.image,
			.cta-link {
				margin: 0 auto;
			}
			.image {
				max-width: 350px;
				height: 200px;
				display: grid;
				align-content: center;
			}
			.cta-link {
				text-align: center;
				.link {
					font-size: 1.2rem;
					line-height: 1.5rem;
					${props => props.theme.media.sdesk} {
						font-size: 1.7rem;
						line-height: 1.9rem;
					}
				}
			}
		}
		.cols {
			.col {
				${props => props.theme.media.sdesk} {
					max-width: 50%;
					&:first-child {
						padding-right: 50px;
					}
					&:last-child {
						padding-left: 50px;
					}
				}
				& > h4 {
					margin-bottom: 10px;
					font-size: 1.5rem;
					line-height: 1.7rem;
					${props => props.theme.media.sdesk} {
						margin-bottom: 20px;
					}
				}
			}
			.soc-col {
				.social-links {
					margin: 0;
					justify-content: space-between;
					a {
						padding: 0;
					}
				}
			}
		}
	}
`

const PageTpl = ({ pageContext, data }) => {
	const toggleGlobalNav = useStoreActions(actions => actions.View.toggleGlobalNav)
	toggleGlobalNav(true)
	const { breadcrumbs } = pageContext
	const { page, socLinksGroup, blogData } = data

	const { content, cta, pic, titleText, textAlign } = page
	const { socLinks } = socLinksGroup

	const blogPost = blogData && blogData.edges.map(({ node }) => node)[0]

	// console.log('data: ', data)
	// console.log('page: ', page)

	return (
		<Wrap textAlign={textAlign}>
			<SEO pageData={page} breadcrumbs={breadcrumbs} />
			<Page pageContext={pageContext} pageData={page}>
				<div className={`page-wrapper`}>
					{get(content, 'json') ? (
						<section className='rt-section'>
							{titleText ? <H1>{titleText}</H1> : null}
							<RT content={content} />
						</section>
					) : null}
					<section className='cta-section'>
						{get(pic, 'fluid') ? (
							<div className='image'>
								<Img fluid={pic.fluid} />
							</div>
						) : null}
						{get(cta, 'link') && get(cta, 'title') ? (
							<div className='cta-link'>
								<CTALink to={cta.link}>{cta.title}</CTALink>
							</div>
						) : null}
					</section>
					<section className='cols flex-desk'>
						<div className='col blog-col'>
							<H4>Read our latest article</H4>
							<Card post={blogPost} />
						</div>
						<div className='col soc-col'>
							<H4>Follow us on social</H4>
							<SocLinks links={socLinks} />
						</div>
					</section>
				</div>
			</Page>
		</Wrap>
	)
}
export const pageQuery = graphql`
	query thankyouPageQuery($pageId: String) {
		page: contentfulThankyouPage(contentful_id: { eq: $pageId }) {
			id: contentful_id
			slug
			titleText
			title: breadcrumb
			textAlign
			content {
				json
			}
			cta {
				title
				link
			}
			pic {
				title
				fluid(maxWidth: 800) {
					...GatsbyContentfulFluid
				}
			}
		}
		socLinksGroup: contentfulSocLinks(contentful_id: { eq: "7vM7bcd7LOaBoHnhCP7vzJ" }) {
			socLinks: accounts {
				link
			}
		}
		blogData: allContentfulBlog(sort: { fields: publishDate, order: DESC }, limit: 2) {
			edges {
				node {
					id: contentful_id
					slug
					title
					shortTitle: breadcrumb
					tags
					publishDate
					createdAt
					updatedAt
					bannerPic {
						fluid {
							...GatsbyContentfulFluid
						}
						description
						title
					}
					description {
						text: description
					}
					group1: pagegroup {
						id: contentful_id
						title: breadcrumb
						slug
					}
					group2: pagegroup2 {
						id: contentful_id
						title: breadcrumb
						slug
						group: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
					}
				}
			}
		}
	}
`
PageTpl.propTypes = {
	pageContext: PropTypes.shape({
		pageId: PropTypes.string.isRequired,
	}).isRequired,
}
export default PageTpl
