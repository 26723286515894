import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link, Icon } from '../UI'
import { socIconFromLink } from '../../utils'

const Wrap = styled.div`
	margin: 40px 0;
	${props => props.theme.media.sdesk} {
		margin: 100px 0;
	}
	.link {
		padding: 10px;
		color: ${props => props.theme.colors.slate.val};
		${props => props.theme.media.sdesk} {
		}
		.icon {
			height: 25px;
			width: auto;
			${props => props.theme.media.tablet} {
				height: 30px;
			}
		}
		svg {
			height: 100%;
			width: auto;
		}
	}
`

const SocLinks = ({ links }) => {
	return links && links.length ? (
		<Wrap className='social-links flex center'>
			{links.map(social => (
				<Link href={social.link} key={social.id || social.link} noStyles>
					<Icon type={socIconFromLink(social.link)} />
				</Link>
			))}
		</Wrap>
	) : null
}
SocLinks.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			link: PropTypes.string.isRequired,
		})
	),
}
export default SocLinks
