import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { H4, Link, P, Icon, Tag } from '../UI'
import { parsePageURI, getDateFormatted } from '../../utils'

const Article = styled.article`
	flex: 1;
	justify-self: stretch;
	align-self: stretch;
	${props => props.theme.media.sdesk} {
	}
	.pic {
		width: 100% !important;
		height: auto;
		img {
			width: 100%;
			height: auto;
		}
	}
	.inner-content {
		padding: 20px ${props => props.theme.sizes.gutter.mobile.num}px 40px
			${props => props.theme.sizes.gutter.mobile.num}px;
		${props => props.theme.media.sdesk} {
			padding: 20px 0;
		}
		h4 {
			font-size: 1.1rem;
			${props => props.theme.media.sdesk} {
				font-size: 1.3rem;
			}
		}
		.date {
			font-size: 1rem;
			${props => props.theme.media.sdesk} {
				font-size: 16px;
			}
		}
		.cta-faux-link {
			color: ${props => props.theme.colors.orange.val};
			font-size: 1em;
			font-weight: 500;
			display: block;
			margin-bottom: 1em;
		}
		.tags {
			display: none;
			${props => props.theme.media.sdesk} {
			}
			.tag {
				margin: 2px 0;
				margin-right: 0.3em;
			}
		}
	}
`

const BlogCard = ({ post }) => {
	const { title, bannerPic, createdAt, description, tags } = post
	return (
		<Article>
			<Link noStyles to={parsePageURI(post)}>
				{bannerPic && <Img className='pic' fluid={bannerPic.fluid} />}
				<div className={`inner-content`}>
					<div className='text'>
						<H4 className='title'>{title}</H4>
						<div className='date'>{getDateFormatted(createdAt)}</div>
						{description && <P>{description.text}</P>}
					</div>
					<span className='cta-faux-link'>
						Read the article <Icon type='arrow' />
					</span>
					{tags && tags.length ? (
						<div className='tags'>
							{tags.map(tag => (
								<Tag key={tag}>{tag}</Tag>
							))}
						</div>
					) : null}
				</div>
			</Link>
		</Article>
	)
}
BlogCard.propTypes = {
	post: PropTypes.shape({
		title: PropTypes.string,
	}),
}
export default BlogCard
